.quill-wrapper {
    padding: 10px 0;
    /* max-height: 30px; Set max height for the editor */
    overflow: hidden; /* Prevent overflow from the wrapper */
  }
  
  .ql-toolbar {
    border: 1px solid #ccc; /* Optional: styling for the toolbar */
    background: #eff2f7; /* Optional: background color */
  }
  
  .ql-container {
    background-color: #FCFCFC;
    height: 100%; /* Adjust the height to account for the toolbar */
    max-height: 380px !important; /* Ensure the container doesn't exceed max height */
    overflow-y: auto !important; /* Enable scrolling for the content */
  }
  
  .blot-formatter__toolbar-button {
    display: inline-flex !important;  /* Changed to inline-flex to center the svg */
    justify-content: center;
    align-items: center;
  }
