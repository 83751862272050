.preview-html .ql-align-center {
    display: flex;
    justify-content: center;
}
  
.preview-html {
  margin-top: 30px;
  a {
    color: #2C75C5;
    margin: 0 5px;
    text-decoration: none;
    }
  p {
    /* color: #FCFCFC !important; */
    background: transparent;
    margin: 0;
  }
  img {
    max-width: 100%;
    object-fit: contain;
  }
}

@media screen and (max-width: 500px) {
  .preview-html .ql-align-center {
      display: flex;
      justify-content: center;
    }
  
    .preview-html {
      margin-top: 30px;
    }

    .preview-html a {
      color: #2C75C5;
      text-decoration: none;
    }
  .preview-html img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: contain;  
  }
  .preview-html p, 
  .preview-html h1, 
  .preview-html h2, 
  .preview-html h3, 
  .preview-html h4, 
  .preview-html h5, 
  .preview-html h6 {
    width: 100%;
    margin: 0;
    word-break: break-word;
  }
}
